import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"

const PreviewCompatibleImage = ({
  image,
  type,
  alt = "",
  gatsbyImageProps,
  rawImageProps,
  ...rest
}) => {
  if (!!image && !!image.childImageSharp) {
    if (type === "fluid") {
      return <Img {...rest} {...gatsbyImageProps} fluid={image.childImageSharp.fluid} alt={alt} />
    } else if (type === "fixed") {
      return <Img {...rest} {...gatsbyImageProps} fixed={image.childImageSharp.fixed} alt={alt} />
    }
  }

  if (!!image && typeof image === "string") {
    return <img {...rest} {...rawImageProps} src={image} alt={alt} />
  }

  return false
}

PreviewCompatibleImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(["fluid", "fixed"]).isRequired,
  alt: PropTypes.string,
  gatsbyImageProps: PropTypes.object,
  rawImageProps: PropTypes.object,
}

export default PreviewCompatibleImage
