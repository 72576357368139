import { faBars } from "@fortawesome/pro-light-svg-icons/faBars"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import LogoFullImg from "../../img/logo-full.svg"
import LogoSmallImg from "../../img/logo-small.svg"
import { colors } from "../../theme"
import PreviewCompatibleImage from "../preview-compatible-image"
import Desktop from "./desktop"
import Drawer from "./drawer"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}))

export const HeaderTemplate = ({ data, logo, primaryAction }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const { navitems } = data

  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  const navigationItems = []

  if (navitems && navitems.length) {
    navitems.forEach(navitem => {
      navigationItems.push({
        title: navitem.title,
        columns: navitem.columns
          ? navitem.columns.map(column => ({
              title: column.title,
              bgcolor:
                column.background === "gray"
                  ? colors.grayLightest
                  : theme.palette.common.white,
              items: column.rows
                ? column.rows.map(row => ({
                    ...row,
                    link: row.productlink ? `/${row.productlink}` : row.link,
                  }))
                : [],
            }))
          : [],
      })
    })
  }

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        color="inherit"
        elevation={scrollTrigger ? 4 : 0}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link
              to={logo && logo.link ? logo.link : "/"}
              component={AdapterLink}
            >
              {logo && logo.full && logo.small ? (
                <PreviewCompatibleImage
                  type="fixed"
                  image={lg ? logo.full : logo.small}
                  alt={logo.alt}
                  rawImageProps={{ height: 32 }}
                />
              ) : (
                <img
                  height={32}
                  src={lg ? LogoFullImg : LogoSmallImg}
                  alt="Ship.Cars"
                />
              )}
            </Link>
            <Box flexGrow={1}>{md && <Desktop items={navigationItems} />}</Box>
            <Box minWidth={135} mx={1}>
              {primaryAction}
            </Box>
            {!md && !!navigationItems.length && (
              <IconButton
                edge="end"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <FontAwesomeIcon icon={faBars} size="sm" />
              </IconButton>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        open={open}
        onDrawerOpen={handleDrawerOpen}
        onDrawerClose={handleDrawerClose}
        items={navigationItems}
      />
    </React.Fragment>
  )
}

const Header = ({ data, ...rest }) => {
  if (!data) {
    return null
  }

  return <HeaderTemplate data={data.frontmatter} {...rest} />
}

export { Header }
