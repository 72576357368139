export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 20
export const DOT_MIN_LENGTH = 6
export const DOT_MAX_LENGTH = 7
export const PHONE_NUMBER_REGEX = /^\(\d{3}\) \d{3}-\d{4}$/
export const DOT_REGEX = /^\d+$/
export const SECTION_VERTICAL_PADDING = 7
export const VIDEO_BORDER_RADIUS = 16
export const TRANSLATE_ANIME_CONFIG = {
  delay: 5,
  duration: 450,
  translateY: 50,
  direction: "reverse",
  opacity: [1, 0],
  easing: "easeInOutSine",
}
