import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import anime from "animejs/lib/anime.es.js"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import grayAccentImg from "../../img/gray-accent.png"
import purpleAccentImg from "../../img/purple-accent.png"
import { colors } from "../../theme"

function MenuItemLink({ to, gatsbyLinkProps, ...rest }) {
  const internal = to.startsWith("/")
  return (
    <MenuItem
      component={internal ? GatsbyLink : "a"}
      {...(internal
        ? {
            to,
          }
        : {
            href: to,
          })}
      {...rest}
      {...(internal ? gatsbyLinkProps : {})}
    />
  )
}

const useStyles = makeStyles(theme => ({
  description: {
    whiteSpace: "normal",
  },
  title: {
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
  },
  listItemIcon: {
    alignSelf: "flex-start",
    paddingTop: 5,
    paddingBottom: 5,
    backgroundImage: `url(${grayAccentImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "17px 14px",
    backgroundPosition: "left 10px bottom -1px",
  },
  activeItem: {
    "& $listItemIcon": {
      backgroundImage: `url(${purpleAccentImg})`,
    },
    "& $title": {
      color: colors.shipCars,
    },
  },
}))

const DesktopMenuItem = ({
  row,
  column,
  icon,
  link,
  title,
  description,
  onClick,
}) => {
  const classes = useStyles()
  const [hovered, setHovered] = React.useState(false)
  const descriptionRef = React.useRef(null)
  const [showLearnMore, setShowLearnMore] = React.useState(false)

  React.useEffect(() => {
    if (descriptionRef && descriptionRef.current) {
      setShowLearnMore(true)
    }
  }, [])

  const handleItemEnter = () => {
    setHovered(true)

    const tl = anime.timeline({
      easing: "easeInOutQuad",
    })

    anime.remove(`.description-${column}-${row}`)
    tl.add(
      {
        targets: `.description-${column}-${row}`,
        duration: 400,
        translateY: -descriptionRef.current.clientHeight,
      },
      0
    )

    anime.remove(`.learn-more-${column}-${row}`)
    tl.add(
      {
        targets: `.learn-more-${column}-${row}`,
        duration: 400,
        translateY: -descriptionRef.current.clientHeight,
      },
      0
    )

    anime.remove(`.learn-more-arrow-${column}-${row}`)
    tl.add({
      targets: `.learn-more-arrow-${column}-${row}`,
      translateX: 5,
      duration: 300,
    }, 200)
  }

  const handleItemLeave = () => {
    setHovered(false)

    const tl = anime.timeline({
      easing: "easeInOutQuad",
    })

    anime.remove(`.description-${column}-${row}`)
    tl.add({
      targets: `.description-${column}-${row}`,
      duration: 400,
      translateY: 0,
    }, 0)

    anime.remove(`.learn-more-${column}-${row}`)
    tl.add({
      targets: `.learn-more-${column}-${row}`,
      duration: 400,
      translateY: 0,
    }, 0)

    anime.remove(`.learn-more-arrow-${column}-${row}`)
    tl.add({
      targets: `.learn-more-arrow-${column}-${row}`,
      translateX: 0,
      duration: 300,
    }, 200)
  }

  return (
    <MenuItemLink
      // gatsbyLinkProps={{
      //   activeClassName: classes.activeItem,
      // }}
      className={hovered ? classes.activeItem : ""}
      onClick={onClick}
      to={link}
      id={`Main Nav Item - ${title}`}
      onMouseEnter={handleItemEnter}
      onMouseLeave={handleItemLeave}
    >
      {icon && (
        <ListItemIcon className={classes.listItemIcon}>
          <FontAwesomeIcon
            icon={{
              prefix: "fal",
              iconName: icon,
            }}
            size="1x"
          />
        </ListItemIcon>
      )}
      <Box>
        <Box className={classes.title}>
          <Typography variant="subtitle2" color="inherit">
            <strong>{title}</strong>
          </Typography>
        </Box>
        <Box
          overflow="hidden"
          height={
            descriptionRef && descriptionRef.current
              ? descriptionRef.current.clientHeight
              : "auto"
          }
        >
          <Typography
            ref={descriptionRef}
            className={`${classes.description} description-${column}-${row}`}
            component="p"
            variant="caption"
            color="textSecondary"
          >
            {description}
          </Typography>
          {showLearnMore && descriptionRef && descriptionRef.current && (
            <Box className={`learn-more-${column}-${row}`} color={colors.link}>
              <Typography component="p" variant="caption" color="inherit">
                <strong>Learn more</strong>{" "}
                <FontAwesomeIcon
                  className={`learn-more-arrow-${column}-${row}`}
                  icon={faArrowRight}
                  size="xs"
                />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </MenuItemLink>
  )
}

export default DesktopMenuItem
