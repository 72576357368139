import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons/faChevronUp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import { makeStyles } from "@material-ui/core/styles"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/styles"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import grayAccentImg from "../../img/gray-accent.png"
import { colors } from "../../theme"

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      backgroundColor: "rgba(0, 0, 0, .03)",
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails)

function ListItemLink({ to, gatsbyLinkProps, ...rest }) {
  const internal = to.startsWith("/")

  return (
    <ListItem
      button
      component={internal ? GatsbyLink : "a"}
      {...(internal
        ? {
            to,
          }
        : {
            href: to,
          })}
      {...rest}
      {...(internal ? gatsbyLinkProps : {})}
    />
  )
}

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  listItemIcon: {
    backgroundImage: `url(${grayAccentImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "17px 14px",
    backgroundPosition: "left 10px bottom -1px",
  },
}))

const Drawer = ({ items, open, onDrawerOpen, onDrawerClose }) => {
  const classes = useStyles()
  const [selectedNavItem, setSelectedNavItem] = React.useState(null)

  const handlePanelChange = panel => (event, newExpanded) => {
    setSelectedNavItem(newExpanded ? panel : null)
  }

  return (
    <SwipeableDrawer
      open={open}
      anchor="right"
      onOpen={onDrawerOpen}
      onClose={onDrawerClose}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={onDrawerClose}>
          <FontAwesomeIcon icon={faTimes} size="sm" />
        </IconButton>
      </div>
      <Divider />
      <Box minWidth={320} width="40vw">
        {items.map((item, index) => (
          <ExpansionPanel
            key={index}
            square
            expanded={selectedNavItem === index}
            onChange={handlePanelChange(index)}
          >
            <ExpansionPanelSummary
              expandIcon={<FontAwesomeIcon size="xs" icon={faChevronUp} />}
            >
              <Typography style={{ fontWeight: 900 }} variant="body1" noWrap>
                {item.title}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box width="100%">
                {item.columns.map((column, index) => (
                  <List
                    key={index}
                    dense
                    component="div"
                    disablePadding
                    subheader={
                      <ListSubheader component="div">
                        <Box color={colors.grayMid}>
                          <Typography
                            style={{
                              textTransform: "uppercase",
                              fontWeight: 900,
                            }}
                            variant="caption"
                            color="inherit"
                          >
                            {column.title}
                          </Typography>
                        </Box>
                      </ListSubheader>
                    }
                  >
                    {column.items.map((el, index) => (
                      <ListItemLink
                        key={index}
                        to={el.link}
                        id={`Main Nav Item - ${el.title}`}>
                        {el.icon && (
                          <ListItemIcon className={classes.listItemIcon}>
                            <FontAwesomeIcon
                              icon={{
                                prefix: "fal",
                                iconName: el.icon,
                              }}
                              size="lg"
                            />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={el.title}
                          primaryTypographyProps={{ variant: "body2" }}
                        />
                      </ListItemLink>
                    ))}
                  </List>
                ))}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </Box>
    </SwipeableDrawer>
  )
}

export default Drawer
