import { graphql } from "gatsby"
import React from "react"
import { Footer } from "./footer"
import { Header } from "./header"

const Page = ({
  primaryAction = null,
  logo = null,
  headerData = null,
  footerData = null,
  hideFooter = false,
  children,
}) => {
  return (
    <React.Fragment>
      <Header primaryAction={primaryAction} logo={logo} data={headerData} />
      {children}
      {!hideFooter && <Footer data={footerData} />}
    </React.Fragment>
  )
}

export const query = graphql`
  fragment PageFragment on Query {
    footerData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/footer.md/" }
    ) {
      frontmatter {
        address
        suite
        companyfullname
        city
        state
        zip
        contactlinks {
          formatted
          href
        }
        products {
          link
          title
        }
        resources {
          link
          title
        }
        company {
          link
          title
        }
        social {
          link
          title
          icon
        }
      }
    }
    headerData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/header.md/" }
    ) {
      frontmatter {
        navitems {
          title
          columns {
            title
            background
            rows {
              title
              description
              productlink
              link
              icon
            }
          }
        }
      }
    }
  }
`

Page.propTypes = {}

export default Page
