import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import ListSubheader from "@material-ui/core/ListSubheader"
import MenuList from "@material-ui/core/MenuList"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import DesktopMenuItem from "./desktop-menu-item"
import { colors } from "../../theme"

const useStyles = makeStyles(theme => ({
  background: {
    opacity: 0.6,
  },
}))

const Desktop = ({ items }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [selectedNavItem, setSelectedNavItem] = React.useState(null)

  const handleMenuOpen = menu => () => {
    setSelectedNavItem(menu)
  }

  const handleMenuClose = () => {
    setSelectedNavItem(null)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {items.map((item, index) => (
        <Box
          key={index}
          position="relative"
          onMouseEnter={handleMenuOpen(index)}
          onMouseLeave={handleMenuClose}
        >
          <Box mx={2}>
            <Button value={index} onClick={handleMenuOpen(index)}>
              {item.title}
            </Button>
            {selectedNavItem === index && (
              <Box
                boxShadow={3}
                width={theme.spacing(2)}
                height={theme.spacing(2)}
                position="absolute"
                bgcolor={theme.palette.common.white}
                left="50%"
                bottom={`-${theme.spacing(3)}px`}
                style={{
                  boxShadow: "-3px -3px 4px rgba(0, 0, 0, 0.1)",
                  transform: "translateX(-50%) rotate(45deg)",
                }}
                zIndex={2}
              >
                <Box
                  style={{ opacity: 0.6 }}
                  height="100%"
                  width="100%"
                  bgcolor={
                    item.columns.length
                      ? item.columns[0].bgcolor
                      : theme.palette.common.white
                  }
                />
              </Box>
            )}
          </Box>
          {selectedNavItem === index && (
            <Box
              position="absolute"
              zIndex={1}
              pt={2}
              left={item.columns.length > 1 ? -80 : "-50%"}
              maxWidth={700}
            >
              <Box
                display="flex"
                boxShadow={3}
                borderRadius={5}
                overflow="hidden"
              >
                {item.columns.map((column, columnIndex) => (
                  <Box key={columnIndex} overflow="hidden" minWidth={250}>
                    <Box
                      position="relative"
                      height="100%"
                      flexGrow={1}
                      flexShrink={1}
                      flexBasis="auto"
                      bgcolor={theme.palette.common.white}
                    >
                      <Box
                        className={classes.background}
                        position="absolute"
                        height="100%"
                        width="100%"
                        bgcolor={column.bgcolor}
                      />
                      <Box px={1}>
                        <MenuList
                          subheader={
                            <ListSubheader component="div">
                              <Box color={colors.grayMid}>
                                <Typography
                                  style={{
                                    textTransform: "uppercase",
                                    fontWeight: 900,
                                  }}
                                  variant="caption"
                                  color="inherit"
                                >
                                  {column.title}
                                </Typography>
                              </Box>
                            </ListSubheader>
                          }
                        >
                          {column.items.map((el, rowIndex) => (
                            <DesktopMenuItem
                              key={rowIndex}
                              row={rowIndex}
                              column={columnIndex}
                              {...el}
                              onClick={handleMenuClose}
                            />
                          ))}
                        </MenuList>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  )
}

export default Desktop
