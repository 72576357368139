import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { SECTION_VERTICAL_PADDING } from "../constants"
import LogoFullBlack from "../img/logo-full-black.svg"
import { colors } from "../theme"

const copyrightYear = new Date().getFullYear()

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const useStyles = makeStyles(theme => ({
  pageFooter: {
    borderTop: `1px solid ${colors.borderColor}`,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  copyright: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  logo: {
    marginBottom: 25,
  },
}))

export const FooterTemplate = ({ data }) => {
  const classes = useStyles()
  const {
    companyfullname,
    address,
    suite,
    city,
    state,
    zip,
    contactlinks,
    resources,
    products,
    company,
    social,
  } = data

  return (
    <footer className={classes.pageFooter}>
      <Container maxWidth="lg">
        <Box py={SECTION_VERTICAL_PADDING}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={2}>
              <div className={classes.logo}>
                <img height={32} src={LogoFullBlack} alt="Ship.Cars logo" />
              </div>
              <Box pb={2}>
                <Typography variant="body1" color="textSecondary">
                  {companyfullname}
                </Typography>
              </Box>
              <Box pb={2}>
                <Typography variant="body1" color="textSecondary">
                  {address}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {suite}
                </Typography>
                <Typography variant="body1" gutterBottom color="textSecondary">
                  {city}, {state} {zip}
                </Typography>
              </Box>
              {contactlinks.map((el, index) => (
                <Link
                  key={index}
                  href={el.href}
                  variant="body1"
                  color="textSecondary"
                  display="block"
                >
                  {el.formatted}
                </Link>
              ))}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                PRODUCTS
              </Typography>
              {products.map((el, index) => (
                <Link
                  key={index}
                  id={`Footer link - ${el.title}`}
                  component={AdapterLink}
                  to={`/${el.link}`}
                  variant="body1"
                  color="textPrimary"
                  display="block"
                  gutterBottom
                >
                  {el.title}
                </Link>
              ))}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                RESOURCES
              </Typography>
              {resources.map((el, index) => (
                <Link
                  key={index}
                  component={el.link.startsWith("/") ? AdapterLink : "a"}
                  {...(el.link.startsWith("/")
                    ? {
                        to: el.link,
                      }
                    : {
                        href: el.link,
                      })}
                  target={el.link.startsWith("/") ? "_self" : "_blank"}
                  variant="body1"
                  color="textPrimary"
                  gutterBottom
                  display="block"
                >
                  {el.title}
                </Link>
              ))}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                COMPANY
              </Typography>
              {company.map((el, index) => (
                <Link
                  key={index}
                  component={el.link.startsWith("/") ? AdapterLink : "a"}
                  {...(el.link.startsWith("/")
                    ? {
                        to: el.link,
                      }
                    : {
                        href: el.link,
                      })}
                  target={el.link.startsWith("/") ? "_self" : "_blank"}
                  variant="body1"
                  color="textPrimary"
                  display="block"
                  gutterBottom
                >
                  {el.title}
                </Link>
              ))}
            </Grid>
            <Grid item xs={6} md={3} lg={4}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                SOCIAL
              </Typography>
              {social.map((el, index) => (
                <Link
                  key={index}
                  href={el.link}
                  target={el.link.startsWith("/") ? "_self" : "_blank"}
                  variant="body1"
                  color="textPrimary"
                  gutterBottom
                  display="block"
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={{ prefix: "fab", iconName: el.icon }}
                    fixedWidth
                    size="lg"
                  />
                  {el.title}
                </Link>
              ))}
            </Grid>
          </Grid>
          <div className={classes.copyright}>
            <Typography align="center">
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
              >
                Copyright {copyrightYear}. All Rights Reserved.
              </Typography>{" "}
              <Link
                component={AdapterLink}
                variant="body2"
                color="textPrimary"
                to="/legal/privacy-policy"
              >
                Privacy Policy
              </Link>
            </Typography>
          </div>
        </Box>
      </Container>
    </footer>
  )
}

const Footer = props => {
  if (!props.data) {
    return null
  }

  const data = props.data.frontmatter

  return <FooterTemplate data={data} />
}

export { Footer }
